import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyFacturacionSencilla: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Facturación sencilla
            </h2>
            <h3>
                ¿Cómo empezar a facturar un pedido?
            </h3>
            <div className="row two centered">
                <ImageFooted explanation={`Desde la vista de detalle del pedido en Shopify.`}>
                    <IMG_Shopify file={'billStartOrder'} />
                </ImageFooted>
                <ImageFooted explanation={`Desde la app de FiscalPOP en "Mis Pedidos".`}>
                    <IMG_Shopify file={'billStartPedidos'} />
                </ImageFooted>
            </div>
            <p>
                Una vez que selecciones la órden a facturar, verás la ventana siguiente donde podrás editar la factura antes de emitirla. <br />
Los productos a los que no hayas configurado clave de productos y unidades del SAT utilizarán las claves default.
            </p>
            <h3>
                Facturación manual desde la APP
            </h3>
            <ol>
                <li>
                    <p><b>Producto "Cenicero JSON Tray":</b> Es un producto normal desde el inventario de Shopify, este producto ya cuenta con sus claves customizadas y se declaró en un Pedimento, por lo que se muestra el pedimento activo. <br />
                    <span className="small quote"> Si tus productos son de importacion y quieres saber como se administran los pedimentos en FiscalPOP, entra <Link to="../claves-pedimentos">aquí</Link></span>
                    </p>
                </li>
                <li>
                    <p><b>Producto "Some things":</b> Es un producto que no existe en el inventario de Shopify y fué agregado como <span className="accent">"Custom Item"</span> al momento de generar la orden. <br />
                    Por default cuenta con las claves predeterminadas que se agregaron en "Mi configuración", pero puedes cambiarlas dando click en el lápiz. <br />
                    Este tipo de productos puedes reconocerlos por el ícono de exclamación en rojo.
                    
                    </p>
                </li>
            </ol>
            <ImageFooted explanation={`El IVA se obtiene desde Shopify, Es necesario que tus productos estén configurados con la opción de Taxable encendida para IVA 16% o apagada 0%.`}>
                <IMG_Shopify file={'billComplete'} />
            </ImageFooted>
            <p>
            Parametrizamos el precio del producto y los impuestos seguún configures tu Shopify, <b>La opción de precio incluye impuesto</b> desde tu Configuración de Shopify es la que rige como se calcula el impuesto de tus productos.
            </p>
            <SectionNavigate previous={`/ecommerce/shopify/post-facturacion`} next={`/ecommerce/shopify/facturacion-multiple`}/>
        </div>
    </Layout>
)

export default ShopifyFacturacionSencilla;